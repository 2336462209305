.react-grid-item > .react-resizable-handle::after {
  content: "";
  position: absolute;
  right: 3px;
  bottom: 3px;
  width: 10px;
  height: 10px;
  border-right: 4px solid #FF7E35;
  border-bottom: 4px solid #FF7E35;
}
