.round {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #fff;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  position: relative;
  cursor: pointer;
}

#cta {
  width: 100%;
  cursor: pointer;
}

#cta .arrow {
  left: 30%;
}

.arrow {
  bottom: 0;
  width: 12px;
  height: 12px;
}

.next {
  background-image: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2aWV3Qm94PSIwIDAgNTEyIDUxMiI+PHN0eWxlPi5zdDB7ZmlsbDojZmZmfTwvc3R5bGU+PHBhdGggY2xhc3M9InN0MCIgZD0iTTMxOS4xIDIxN2MyMC4yIDIwLjIgMTkuOSA1My4yLS42IDczLjdzLTUzLjUgMjAuOC03My43LjZsLTE5MC0xOTBjLTIwLjEtMjAuMi0xOS44LTUzLjIuNy03My43UzEwOSA2LjggMTI5LjEgMjdsMTkwIDE5MHoiLz48cGF0aCBjbGFzcz0ic3QwIiBkPSJNMzE5LjEgMjkwLjVjMjAuMi0yMC4yIDE5LjktNTMuMi0uNi03My43cy01My41LTIwLjgtNzMuNy0uNmwtMTkwIDE5MGMtMjAuMiAyMC4yLTE5LjkgNTMuMi42IDczLjdzNTMuNSAyMC44IDczLjcuNmwxOTAtMTkweiIvPjwvc3ZnPg==);
}

@keyframes bounceAlpha {
  0% {
    opacity: 1;
    transform: translateX(0px) scale(1);
  }
  25% {
    opacity: 0;
    transform: translateX(10px) scale(0.9);
  }
  26% {
    opacity: 0;
    transform: translateX(-10px) scale(0.9);
  }
  55% {
    opacity: 1;
    transform: translateX(0px) scale(1);
  }
}

.bounceAlpha {
  animation-name: bounceAlpha;
  animation-duration: 1.4s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.arrow.primera.bounceAlpha {
  animation-name: bounceAlpha;
  animation-duration: 1.4s;
  animation-delay: 0.2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.round:hover .arrow {
  animation-name: bounceAlpha;
  animation-duration: 1.4s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
.round:hover .arrow.primera {
  animation-name: bounceAlpha;
  animation-duration: 1.4s;
  animation-delay: 0.2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
