@tailwind components;

@layer components {
  /* Custom Scrollbar */
  ::-webkit-scrollbar {
    z-index: 50;
    width: 13px;
    height: 13px;
  }

  /* Track*/
  ::-webkit-scrollbar-track {
    background-color: #f5f5f5;
  }

  /* Thumb */
  ::-webkit-scrollbar-thumb {
    background-color: #d2d2d2;
    border: 3px solid #f5f5f5;
    border-radius: 45px;
    @apply min-h-[50px];
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: #a3a3a3;
  }

  ::-webkit-scrollbar-corner {
    background-color: #fafafa;
  }
}
