@tailwind components;

.rmsc .dropdown-container {
  @apply rounded-md !border-neutral-border-2 !shadow-none;
}

.rmsc .dropdown-container .dropdown-heading {
  @apply text-sm !rounded-lg;
}

.rmsc .dropdown-container .dropdown-heading svg {
  @apply scale-75;
}

.rmsc .dropdown-container .dropdown-content {
  @apply p-0 mt-4;
}

.rmsc .dropdown-container .dropdown-content .panel-content {
  @apply rounded-lg;
}

.rmsc .select-item {
  @apply !bg-neutral-white text-sm;
}

.rmsc .select-item:hover {
  @apply !bg-neutral-border-1;
}

.rmsc .select-item .item-renderer {
  @apply flex items-center gap-2;
}
