/* Import Google Font */
@import url('https://fonts.googleapis.com/css2?family=Instrument+Sans:ital,wght@0,400..700;1,400..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Grey+Qo&family=Oswald:wght@200;300;400;500;600;700&display=swap');

/* Tailwind CSS base styles and utilities */
@tailwind base;
@tailwind utilities;

/* Importing other necessary CSS files */
@import 'rc-picker/assets/index.css';
@import './components/table/style.css';
@import './styles/export.css';
@import './styles/scrollbar.css';
@import './styles/multi-select.css';

/* Global, use 'Grey Qo' when in doubt about fonts. */
* {
  /* font-family: 'Grey Qo', Inter; */
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
  overflow: hidden;
}

#button-container {
  display: block !important;
  padding: 10px;
  height: 80px;
  width: 70px;
  position: fixed;
  border: 0px;
  bottom: 0px;
  right: 0px;
  z-index: 2147483647;
  transition: height;
}

#button-container #help-button.icon {
  width: 50px;
  font-size: 29px;
}

#button-container #help-button {
  background-color: #0052cc;
  color: #ffffff;
  outline: none;
  height: 50px;
  line-height: 50px;
  border-radius: 999px;
  text-align: center;
  text-decoration: none;
  box-shadow: 0 1px 2px 0 #7a869a;
  font-weight: bold;
  cursor: pointer;
}

#help-button {
  background-color: #ff7e35 !important;
}

#button-container #help-button.icon {
  width: 50px;
  font-size: 29px;
}

#button-container #help-button {
  background-color: #0052cc;
  color: #ffffff;
  outline: none;
  height: 50px;
  line-height: 50px;
  border-radius: 999px;
  text-align: center;
  text-decoration: none;
  box-shadow: 0 1px 2px 0 #7a869a;
  font-weight: bold;
  cursor: pointer;
}

/* Utility outlines for frontend to use with tailwind */
@layer utilities {
  .OUTLINEBLUE {
    @apply outline outline-2 outline-blue;
  }
  .OUTLINERED {
    @apply outline outline-2 outline-red;
  }
  .OUTLINEGREEN {
    @apply outline outline-2 outline-green;
  }
}

/* Toastify container z-index adjustment */
.Toastify__toast-container {
  z-index: 99999 !important;
}

/* Animation helpers */

@keyframes borderPulse {
  0% {
    border-color: rgb(0, 0, 0);
  }
  33% {
    border-color: rgb(255, 133, 0);
  }

  100% {
    border-color: rgb(0, 0, 0);
  }
}

@keyframes textPulse {
  0% {
    color: rgb(0, 0, 0);
  }
  33% {
    color: rgb(255, 133, 0);
  }
  100% {
    color: rgb(0, 0, 0);
  }
}

.animate-pulse {
  animation: borderPulse 1.5s ease-in-out 3, textPulse 1.5s ease-in-out 3;
}

/* Create the pulsing ring effect */
@keyframes concentricPulse {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.5);
    opacity: 0;
  }
  100% {
    transform: scale(1.3);
    opacity: 0;
  }
}

.pulse-layer-1,
.pulse-layer-2 {
  position: absolute;
  animation: concentricPulse 2.5s ease-out infinite;
}

.pulse-layer-2 {
  animation-delay: 1s; /* Creates staggered effect */
}
