@tailwind components;

@layer components {
  #exportdiv {
    @apply h-[38px];
  }
  #exportdiv > div {
    @apply !relative h-[38px];
  }
  #exportdiv > div > a {
    @apply h-[38px] border !border-neutral-border-2 !rounded-md relative bg-neutral-white m-0 flex gap-2 flex-row w-full justify-between items-center px-4;
  }
  #exportdiv > div > a:before {
    @apply content-['Export'] font-medium text-sm;
  }
  #exportdiv > div > ul {
    @apply bg-neutral-white !m-0 !mt-2 border border-neutral-border-2 shadow-lg rounded-lg;
  }
  #exportdiv > div > ul > li:hover {
    @apply bg-primary-surface-2;
  }
  #exportdiv > div > ul > li:hover > a {
    @apply bg-primary-surface-2;
  }
}
